<script>

import api from "@/utils/api";

export default {
  name: "KeywordsEditor",
  props: {
    country: {
      default: ''
    },
    copyKeywords: {default: []},
    copyKeywordsFromCampaignId: {
      default: null
    },
    campaignId: Number,
    keyWords: {
      default: []
    },
    kwAmount: {
      default: 3
    }
  },
  data() {
    return {
      isVisibleErrorDialog: false,
      errorMessage: '',
      snackbar: false,
      snackbarText: '',
      loadingKeywords: false,
      keyWordsOrigin: [],
      keyWordsReady: false,
      thisKeyWords: [],
      keywordsAmount: 3,
      maxKeywords: 10,
      isAllowCopyKeywords: false,
      toId: null
    }
  },
  mounted() {
    // this.getKeywordsInfo()
    // console.log(this.keyWords, this.kwAmount)
    this.refresh(this.keyWords, this.kwAmount)
  },

  computed: {
    isAllowUpdateKeywords() {
      if (this.keyWordsOrigin.length !== this.thisKeyWords.length) {
        return false
      }
      if (this.thisKeyWords.map((k, i) => this.keyWordsOrigin[i] !== k.value).some(v => v)) {
        return false
      }
      if (this.thisKeyWords.some(k => k.new)) {
        return false
      }
      if (this.thisKeyWords.some(k => k.delete)) {
        return false
      }
      return true
    },
  },

  methods: {
    onAddNewKeywordRow() {
      this.thisKeyWords.push({value: '', delete: false, new: true})
      this.keywordsAmount = this.thisKeyWords.filter(f => !f.delete).length
    },
    onDeleteKeywordRow(i) {
      this.thisKeyWords[i].new ? this.thisKeyWords.splice(i, 1) : this.thisKeyWords[i].delete = true
      this.keywordsAmount = this.thisKeyWords.filter(f => !f.delete).length
    },

    onUndoKeywordRow(i) {
      this.thisKeyWords[i].delete = false
      this.keywordsAmount = this.thisKeyWords.filter(f => !f.delete).length
    },

    onChangeKeywordsAmount() {
      if (this.keywordsAmount > this.thisKeyWords.length) {
        Array.from({length: this.keywordsAmount - this.thisKeyWords.length}, () => this.thisKeyWords.push({
          value: '',
          delete: false,
          new: true
        }))
      } else {
        Array.from({length: this.thisKeyWords.length - this.keywordsAmount}, (_, i) => this.thisKeyWords.toReversed()[i].delete = true)
      }
    },
    /*
    checkCopyId(id) {
      if (id && typeof id === "string" && id.length > 0) {
        const ids = id.split(',')
        if (ids.length > 1) {
          this.isUseListOfId = true
          this.isAllowCopyKeywords = true
        } else {
          const intId = parseInt(id)
          if (this.campaignId === intId) {
            this.isUseListOfId = false
            return 'Not this campaign'
          }
          if (isFinite(intId)) {
            this.isUseListOfId = false
            this.isAllowCopyKeywords = true
            return true
          }
        }
      }
      this.isUseListOfId = false
      this.isAllowCopyKeywords = false
      return 'Please number'
    }
     */
    checkCopyId(id) {
      if (id && typeof id === "string" && id.length > 0) {
        const intId = parseInt(id)
        if (this.campaignId === intId) {
          return 'Not this campaign'
        }
        if (isFinite(intId)) {
          this.isAllowCopyKeywords = true
          return true
        }
      }
      this.isAllowCopyKeywords = false
      return 'Please number'
    },

    onCopy() {
      const [keywords] = this.getKeywords()
      this.$emit('copy-keywords', {id: parseInt(this.toId), keywords, from: this.campaignId})
      // this.$emit('copy', {id: parseInt(this.toId), from: this.campaignId})
    },

    async updateKeywords() {
      this.loadingKeywords = false
      // console.log('updateKeywords', this.keyWords)
      const [keywords, keywordAmount] = this.getKeywords()

      // console.log(keywords, keywordAmount)
      try {
        const resp = await api.affNetworkUpdateKeywords({
          network: this.$route.params.network,
          account: this.$route.params.account,
          id: this.campaignId,
          country: this.country,
          keywords,
          keywordAmount
        })
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
        } else {
          // console.log('updateKeywords 1', resp)
          this.$emit('update', true)
          await this.getKeywordsInfo()
        }
      } catch (e) {
        console.error('updateKeywords', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }
    },

    refresh(keyWords, kwAmount) {
      if (keyWords === null) {

        this.keyWordsOrigin = new Array(kwAmount).fill('')
        this.thisKeyWords = Array.from({length: kwAmount}, () => Object.assign({}, {
          value: '',
          delete: false
        }))


        this.copyKeywords.forEach((k, i) => {
          if (this.thisKeyWords[i] === undefined) {
            this.thisKeyWords.push({
              value: k,
              delete: false
            })
          } else {
            this.thisKeyWords[i].value = k
          }
        })

      } else if (Array.isArray(keyWords)) {

        this.keyWordsOrigin = JSON.parse(JSON.stringify(keyWords))
        this.thisKeyWords = this.keyWordsOrigin.map(k => ({
          value: k,
          delete: false
        }))
        if (kwAmount !== this.thisKeyWords.length) {
          if (kwAmount > this.thisKeyWords.length)
            new Array(kwAmount - this.thisKeyWords.length).fill(0).forEach(() => {
              this.keyWordsOrigin.push('')
              this.thisKeyWords.push({
                value: '',
                delete: false
              })
            })
        }

        if (this.copyKeywords.length > 0) {
          if (this.copyKeywords.length === this.thisKeyWords.length) {
            this.thisKeyWords = this.thisKeyWords.map((k, i) => {
              k.value = this.copyKeywords[i]
            })
          } else if (this.copyKeywords.length > this.thisKeyWords.length) {
            this.copyKeywords.forEach((k, i) => {
              if (this.thisKeyWords[i] === undefined) {
                this.thisKeyWords.push({
                  value: k,
                  delete: false
                })
              } else {
                this.thisKeyWords[i].value = k
              }
            })
          } else if (this.copyKeywords.length < this.thisKeyWords.length) {
            this.thisKeyWords.length = this.copyKeywords.length
            this.thisKeyWords = this.thisKeyWords.map((k, i) => {
              k.value = this.copyKeywords[i]
              // console.log(k.value, this.copyKeywords[i])
              return k
            })

          }
        }
      }

      this.keywordsAmount = this.thisKeyWords.length
      this.keyWordsReady = true
    },

    getKeywords() {
      let keywordAmount = 0
      const keywords = this.thisKeyWords.reduce((acc, item) => {
        if (!item.delete && typeof item.value === 'string' && item.value.length > 0) {
          acc.push(item.value)
          keywordAmount++
        }
        if (!item.delete && ((typeof item.value === 'string' && item.value.length === 0) || item.value === null)) {
          keywordAmount++
        }
        return acc
      }, [])
      return [keywords, keywordAmount]
    },

    async getKeywordsInfo() {
      this.loading = true
      this.keyWordsReady = false

      const request = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        campaignId: this.campaignId
      }

      try {
        const resp = await api.affNetworkGetCampaignInfo(request)

        if (resp.data.keywords?.Keywords === 'null') {
          this.refresh(null, resp.data.keywords.KwAmount)
        } else if (Array.isArray(resp.data.keywords?.Keywords)) {
          this.refresh(resp.data.keywords.Keywords, resp.data.keywords.KwAmount)
        }

      } catch (e) {
        console.error('getKeywordsInfo', e)
      } finally {
        this.loading = false
      }


    },
    // copyKeywordsToId({id, from}) {
    //   const [keywords,] = this.getKeywords()
    //   this.$emit('copy-keywords', {id, keywords, from})
    //   this.dialog = false
    // },

    copyToClipboard(text) {
      this.$copyText(text, this.$refs.dialog)
      this.snackbarText = text
      this.snackbar = true
    }
  },
  watch: {
    keyWords(val) {
      this.keyWordsReady = false
      this.refresh(val, this.kwAmount)
    },

    kwAmount(val, old) {
      if (val !== old) {
        this.keyWordsReady = false
        this.refresh(this.keyWords(), val)
      }
    }
  }
}
</script>

<template>
  <div>
    <v-dialog v-model="isVisibleErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>
    <v-row>
      <v-col justify="center" align="center" class="title pa-0 ma-0">Keyword Settings</v-col>
    </v-row>
    <v-row>
      <v-col>
        This optional feature allows you to use keywords of your choice.<br>

        Should you wish to insert your keywords, we recommend the dynamic location parameters for city, state
        and country, especially in cases where users expect to see a location. Our system dynamically inserts
        the respective phrases based on the user's location.<br>

        Available are the following location parameters: <b>{City}</b>, <b>{in City}</b>, <b>{Country}</b>,
        <b>{in Country}</b>, <b>{State}</b>
        and <b>{in State}</b>.<br>

        <b>Examples:</b><br>
        Car Vendors Near <b>{City}</b> -> Car Vendors Near New York<br>
        Assisted Living Facilities <b>{in State}</b> -> Assisted Living Facilities In California<br>
        Assisted Living Facilities <b>{in Country}</b> -> Assisted Living Facilities In Spain<br>
        <span class="title">Please note:</span><span class="subtitle-2"> keyword_amount must use between 3 and 10 keywords. If no keyword_amount was provided, we automatically fill 6 keywords.</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" justify="center" align="center" class="title mt-2">Keywords</v-col>
      <v-col cols="12" sm="2">
        <v-select
            v-if="keyWordsReady"
            v-model="keywordsAmount"
            :items="[3,4,5,6,7,8,9,10]"
            hide-details
            :menu-props='{maxHeight: 608}'
            solo
            label="Amount"
            @change="onChangeKeywordsAmount"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="5" v-if="copyKeywords.length > 0" justify="center" align="left" class="title mt-2">
        <span class="title">Copy keywords from : {{ copyKeywordsFromCampaignId }}, Old keywords:</span>
      </v-col>
    </v-row>
    <v-row v-if="keyWordsReady">
      <v-col cols="12" sm="7" class="pt-0 mt-0">
        <v-row v-for="(item, i) in thisKeyWords" :key="item + i + ''">
          <v-text-field v-if="i <= 2"
                        dense
                        v-model="thisKeyWords[i].value"

                        counter
                        :disabled="thisKeyWords[i].delete"

          ></v-text-field>
          <v-text-field
              v-if="i>2"
              dense
              v-model="thisKeyWords[i].value"
              :append-outer-icon="!thisKeyWords[i].delete ?   'mdi-delete': null "
              counter
              :disabled="thisKeyWords[i].delete"
              @click:append-outer="onDeleteKeywordRow(i)"
          ></v-text-field>
          <v-btn icon color="primary"
                 class="pl-2"
                 v-if="thisKeyWords[i].delete"
                 @click="onUndoKeywordRow(i)">
            <v-icon dark>mdi-undo</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" sm="5" class="pt-0 mt-0" v-if="copyKeywords.length === 0">
        <v-row>
          <v-col cols="12" sm="5" class="pl-5 pt-5 title">Copy Keywords to</v-col>
          <v-col cols="12" sm="4" class="pt-0 pt-0">
            <v-text-field v-model="toId" label="ID" :rules="[checkCopyId]"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn color="primary" :disabled="!isAllowCopyKeywords" @click="onCopy">Copy
              <v-icon class="pl-2" dark>mdi-send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else class="ml-3">
        <v-row v-for="(item) in keyWords" :key="item" class="subtitle-1 pb-2 mb-2">
          {{ item }}
          <v-icon class="pl-2" small @click.stop="copyToClipboard(item.toString())">
            mdi-content-copy
          </v-icon>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="thisKeyWords.length - thisKeyWords.filter(f => f.delete).length < maxKeywords">
      <v-col cols="12" sm="6" class="pt-0 mt-0 mr-0 ml-4" align="right">
        <v-btn icon color="primary"
               @click="onAddNewKeywordRow">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col justify="center" align="center" class="pt-0 mt-0">
        <v-btn color="green darken-1" :disabled="isAllowUpdateKeywords" @click="updateKeywords()"
               :loading="loadingKeywords">Update
          Keywords
          <v-icon>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>